var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-card-body",
        { staticClass: "submit-content" },
        [
          _vm.checkoutErrors
            ? _c("banner", {
                staticClass: "mb-4",
                attrs: { message: _vm.checkoutErrors, variant: "failed" },
              })
            : _vm._e(),
          _c(
            "div",
            {
              class: { productInCart: !_vm.cartEmpty },
              attrs: { id: "cart-div" },
            },
            [
              _c("checkout-cart-body", {
                attrs: {
                  id: "verify-order-checkout-cart-body",
                  bus: _vm.bus,
                  dashed: true,
                  total: true,
                  "select-payment-method-card-header-text": "Method of Payment",
                  "alternate-checkout-button-label": _vm.checkoutButtonLabel,
                  "verify-order-checkout": _vm.verifyOrderCheckout,
                  "verify-order-checkout-button-overrides":
                    _vm.verifyOrderCheckoutButtonOverrides,
                },
                on: {
                  "checkout-completed": function ($event) {
                    return _vm.$emit("submit")
                  },
                  "result-banner": _vm.showFailBanner,
                },
                scopedSlots: _vm._u([
                  {
                    key: "cart-header",
                    fn: function () {
                      return [
                        _c("h5", { staticClass: "card-header-text" }, [
                          _vm._v("\n            Order Summary\n          "),
                        ]),
                        _c("hr"),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.checkoutButtonLabel === "Submit" &&
      _vm.cartEmpty &&
      _vm.verifyOrderCheckout
        ? _c(
            "b-card-footer",
            {
              staticClass: "accordion-footer pt-4 remove-line",
              attrs: { "footer-tag": "footer", role: "tab" },
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "button-submit",
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("submit")
                    },
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.checkoutButtonLabel) +
                        "\n      "
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }